/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'channel': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" d="M11.304 4.391a1.992 1.992 0 00-.684-1.18 1.96 1.96 0 00-1.278-.455L9.12 1.491a.998.998 0 00-.636-.765.977.977 0 00-.974.179L6.116 2.099c-.33.282-.727.47-1.152.545l-2.918.519a1.67 1.67 0 00-1.077.693 1.698 1.698 0 00-.277 1.258l.395 2.26c.077.44.324.83.686 1.086.363.257.812.357 1.248.28l.423-.076.287 1.645a1.246 1.246 0 00.505.8 1.222 1.222 0 001.904-.747 1.25 1.25 0 00.01-.483l-.287-1.643.075-.013a2.418 2.418 0 011.268.115l1.716.64c.11.042.228.064.346.064a.98.98 0 00.752-.358 1.001 1.001 0 00.215-.81l-.221-1.264c.44-.156.812-.465 1.048-.871.237-.406.322-.884.242-1.348zM9.907 5.997l-.46-2.634c.306.018.597.14.827.345a1.378 1.378 0 01.293 1.68 1.366 1.366 0 01-.66.609z" _fill="#fff"/>'
  }
})
